
.grey{
    color: grey !important;
}

.red {
    color: red !important
}

.tableMatchAll{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 80%;
}

.imgMatchAllContainer{
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    height: 160px;
}

.imgMatchAll0{
    position: absolute;
    top: 60px;
    left: 0px;
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
    width: 80px;
    height: 40px;
    z-index: 49;
}

.imgMatchAll45{
    position: absolute;
    top:30px;
    left: 10px;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
    width: 80px;
    height: 40px;
}

.imgMatchAll90{
    position: absolute;
    top: 20px;
    left: 40px;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    width: 80px;
    height: 40px;
    z-index: 50;
}

.imgMatchAll135{
    position: absolute;
    top: 30px;
    left: 70px;
    transform: translate(-50%, -50%);
    transform: rotate(135deg);
    width: 80px;
    height: 40px;
}

.imgMatchAll180{
    position: absolute;
    top: 62px;
    left: 80px;
    transform: translate(-50%, -50%);
    transform: rotate(180deg);
    width: 80px;
    height: 40px;
    z-index: 49;
}

.imgMatchAll225{
    position: absolute;
    top: 90px;
    left: 70px;
    transform: translate(-50%, -50%);
    transform: rotate(225deg);
    width: 80px;
    height: 40px;
}

.imgMatchAll270{
    position: absolute;
    top: 100px;
    left: 40px;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    width: 80px;
    height: 40px;
    z-index: 50;
}

.imgMatchAll315{
    position: absolute;
    top: 55%;
    left: 10px;
    transform: translate(-50%, -50%);
    transform: rotate(315deg);
    width: 80px;
    height: 40px;
}

.Circle{
    position: absolute;
    width: 144px;
    height: 144px;
    opacity: 80%;
    border-radius: 50%;
    background-color: white;
}

.CircleOrange{
    background: #FBDDC9;
    border: 2px solid #F4B17E;
}

.CircleNA{
    background: #D3D3D3;
    border: 2px solid #777777;
}

.CircleBlue{
    background: #D9EBFC;
    border: 2px solid #9BC0E7;
}

.VennContainer{
    position: relative;
}


.grey{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.font13pt{
    font-size: 13pt !important;
    font-weight: bold;
    color: #777777;
}

.color777{
    color: #777777;
}

.bolding{
    font-weight: bold;
}

.f15pt{
font-size: 15pt !important;
}

.variantFix{
    padding: 5px;
}

.tableMatchOneCdr{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;

}

.imgMatchOneCdr{
    margin-left: 3%;
    margin-right: 3%;
    width: 94%;
    height: auto;
}

.AbTriple0{
    top: 15%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.AbTriple1{
    height: 30px;
    align-items: center;
    top: 32%;
    left: -10%;
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: center;
}

.AbTriple2{
    height: 30px;
    align-items: center;
    top: 40%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.AbTriple3{
    height: 30px;
    align-items: center;
    top: 32%;
    left: 27%;
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: center;
}

.AbTriple4{
    height: 30px;
    align-items: center;
    bottom: 31%;
    left: -21%;
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: center;
}

.AbTriple5{
    height: 30px;
    align-items: center;
    bottom: 31%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.AbTriple6{
    height: 30px;
    align-items: center;
    bottom: 31%;
    left: 37%;
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: center;
}

.tableMatchOne{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 80%;

}

.imgMatchOne{
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 160px;
}

.textL{
    left: -15%; 
    top: 40%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.textM{
    top: 40%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.textR{
    top: 40%;
    left: 15%; 
    position: absolute;
    width: 100%;
    display: flex;
    z-index: 250;
    justify-content: center;
}

.hcSquare1{
    position: absolute;
    top: 55%;
    left: 50%;
    border-radius: 3px;
    background: #D9EBFC;
    border: 1px solid #9BC0E7;
    padding: 0px;
    width: 50px;
    height: 20px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.hcSquare2{
    position: absolute;
    top: 55%;
    left: 50%;
    border-radius: 3px;
    background: #9BC0E7;
    border: 1px solid #9BC0E7;
    padding: 0px;
    width: 50px;
    height: 20px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.matchContainer{
    position: relative;
}

.line {
    border: 1px solid grey;
    position: absolute;
    width:200%;
    margin-top: auto;
    margin-bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.x{
    position: absolute;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    transform: translate(0%, 0%);
    width: 100%;
}

.lcSquare1{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 3px;
    background: #FBDDC9;
    border: 1px solid #F4B17E;
    padding: 0px;
    width: 50px;
    height: 20px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.lcSquare2{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 3px;
    background: #F4B17E;
    border: 1px solid #F4B17E;
    padding: 0px;
    width: 50px;
    height: 20px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.borderR{
    border-right: 1px solid lightgrey;
}

.spacing{
    padding-top: 5px;
    padding-right: 20%;
}

.colorBlack{
    color:black
}

.darkLetter{
    font-weight: 900;
    font-family: "Courier New", monospace !important;
}

.lightLetter{
    font-family: "Courier New", monospace !important;
}

.grey{
    color: grey !important;
}

.red{
    color: red;
}

a{
    cursor: pointer;
}

.monoFont{
    font-family: "Courier New", monospace !important;
    
}

.widthFix{
    max-width: 500px;
    width: 500px;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: grey;
}

.tdMid{
    vertical-align: middle;
}

.hSquare{
    float: left;
    border-radius: 5px;
    background: #D9EBFC;
    border: 1px solid #9BC0E7;
    width: 20px;
    height: 15px;
}

.lSquare{
    float: left;
    border-radius: 5px;
    background: #FBDDC9;
    border: 1px solid #F4B17E;
    width: 20px;
    height: 15px;
}

.textFlat{
    font-size: 13pt;
    white-space: nowrap;
    overflow-wrap: break-word;
    word-break: break-word;
}

.overlaps{
    font-size: 13pt !important;
    font-weight: bold;
}

.textFlatCenter{
    font-size: 14px;
    white-space: nowrap;
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
}

.textFlatLeft{
    font-size: 14px;
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: left;
}

.wsnw{
    white-space: nowrap;

}


.width990{
    width: 990px;
}
.tableFrame{
    border-radius: 10px;
    border: 2px solid #D3D3D3;
    padding: 5px 5px 5px 5px !important;
    margin: 5px 5px 5px 5px !important;
}

.tableItem{
    vertical-align: center;
    width: auto;
    height: auto;
    margin: 1px 1px 1px 1px;
    padding: 5px 5px 5px 5px;

}

.marginAll5{
    margin: 5px 5px 5px 5px;
}


.img2{
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}

.pCenter{
    text-align: center;
}

.tableMarginWidth{
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;

}

.tableCaption{
    padding-bottom: 15px;
}

.bottomText{
    font-size: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.tabler {
    border-collapse: collapse;
    border-style: hidden;
    margin-right: auto;
    margin-left: auto;

}

.tablerb{
    border: 1px solid lightgrey;
    padding: 1px 3px 1px 3px;
}
.tablerbAudit{
    border: 1px solid lightgrey;
    text-align: left;
    padding: 1px 3px 1px 3px;
}
.overtableWidth{
    width: 1024px;
}

.center{
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
    
}

.centerWording{
    text-align: center;
}

.padding15{
    padding-left: 15px;
    padding-right: 15px;
}

.paddingBottom15{
    padding-bottom: 15;
}

.paddingTitle{
    margin-top: 13px;
    margin-bottom: 28px;
}


.font14{
    font-size: 14px;
    color: #777777;
}

.font14noColor{
    font-size: 14px;
}

.tableMatchAll{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 80%;
}

.imgMatchAllContainer{
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    height: 160px;
}

.imgMatchAll0{
    position: absolute;
    top: 60px;
    left: 0px;
    transform: translate(-50%, -50%);
    transform: rotate(0deg);
    width: 80px;
    height: 40px;
    z-index: 49;
}

.imgMatchAll45{
    position: absolute;
    top:30px;
    left: 10px;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
    width: 80px;
    height: 40px;
}

.imgMatchAll90{
    position: absolute;
    top: 20px;
    left: 40px;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    width: 80px;
    height: 40px;
    z-index: 50;
}

.imgMatchAll135{
    position: absolute;
    top: 30px;
    left: 70px;
    transform: translate(-50%, -50%);
    transform: rotate(135deg);
    width: 80px;
    height: 40px;
}

.imgMatchAll180{
    position: absolute;
    top: 62px;
    left: 80px;
    transform: translate(-50%, -50%);
    transform: rotate(180deg);
    width: 80px;
    height: 40px;
    z-index: 49;
}

.imgMatchAll225{
    position: absolute;
    top: 90px;
    left: 70px;
    transform: translate(-50%, -50%);
    transform: rotate(225deg);
    width: 80px;
    height: 40px;
}

.imgMatchAll270{
    position: absolute;
    top: 100px;
    left: 40px;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    width: 80px;
    height: 40px;
    z-index: 50;
}

.imgMatchAll315{
    position: absolute;
    top: 55%;
    left: 10px;
    transform: translate(-50%, -50%);
    transform: rotate(315deg);
    width: 80px;
    height: 40px;
}

.centerCircle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    z-index: 60;
}
.centerText{
    position: absolute;
    font-size: 14pt;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 70;
}

.grey{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.LcCdrUnderStyle{
    border-bottom: 1px solid lightgrey; 
    text-align: center; 
    padding: 1px 3px 1px 3px; 
    width: "auto"
}

.LcWordSytle{
    width: 90%; 
    margin-left: 5%; 
    margin-right: 5%; 
    font-size: 12px;
}

.LcLineStyle{
    font-size: 12px; 
    line-height: .5;
}

.LcBorder{
    border-bottom: 1px solid lightgrey; 
    width: 20%; 
    height: 30px
}

.LcBoder2{
    border-bottom: 1px solid lightgrey;
}

.MarginBottom15{
    margin-bottom: 15px
}

.Margin0{
    margin: 0; 
}

.ChainWidth{
    width: 30%; 
    vertical-align: top;
}

.ChainPadding{
    padding-bottom: 5px; padding-left: 5px; vertical-align: top; width: 60%
}

.ChainInline{
    width: 100%; 
    text-align: Left; 
    display: inline
}

.ChainMargin{
    padding-right: 2px; 
    height:18px; 
    margin-top: 4px; 
    margin-bottom: 0px
}

.VirtAlign{
    vertical-align: top;
}

.ChainPadding{
    padding-left: 7px; padding-bottom:5px; width: 50%; vertical-align: top
}

.ChainMarginLeft{
    margin-left: 40%; margin-top: 0%; margin-bottom: auto;
}

.ChainWH30{
    width: 30%; height: 99%;
}

.auditWM{
    width: 95%; margin: 15px
}

.textAlignLeft{
    text-align: left
}
.margin15{
    margin: 0px 15px 0px 15px;
}

.padding5{
    padding-left: 5px;
    padding-right: 5px;
}

.margL5{
    margin-left: 5px;
}

.margB0{
    margin-bottom: 0;
}

.mp0{
    margin: 0;
    padding: 0;
}
