.tab{
    border: 1px solid black;
    padding: 5px;
    margin: 5px;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    background: white;
    cursor: pointer;
}

.fixDisplay{
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    margin-top: 1px;
}