:root {
    --backgroundColor: #292929 ; /* default light theme background color */
    --appTextColor: #777; /* default light theme text color */
}

body {
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
    overflow: auto !important;
} 

.appTextColor {
    color: var(--appTextColor)!important;
}

.darkTheme {
    background-color: #292929;
} 

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/* .light  {
    background-color: #fff;
}*/

.darkTheme {
    background-color: #292929;
} 

/* Common */

.mt-100 {
    margin-top: 10%;
}

.appLink {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #337AB7;
    font-weight: 400;
    text-decoration: none !important;
}

.appLink:hover {
    text-decoration: underline !important;
    cursor: pointer;
}

.primaryBtn {
    background-color: #008EC5 !important;
    border: 1px solid #008EC5 !important;
}

.disableBtnBorder {
    border: 1.5px solid #a7acac !important;
}

.deleteBtnColor {
    background-color: #db3838 !important;
    border: 1px solid #db3838 !important;
    color: #fff !important;
}

.moreLink {
    color: #337AB7;
    font-style: italic;
    cursor: pointer;
}

.MuiOutlinedInput-input {
    padding: 12px 14px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    color: var(--appTextColor) !important;
    font-weight: 400 !important;
}

.cursorPointer {
    cursor: pointer;
}

.pe-none {
    pointer-events: none;
}

.MuiTreeItem-group {
    margin-left: 32px !important
}

.disabled {
    /* pointer-events: none;
    color: var(--appTextColor);
    cursor: not-allowed; */
}

.failedIconColor {
    color: #DB862D !important;
}

.failedTextColor {
    color: #e17a47 !important;
}


/* Login & Forgot */

.loginTitle {
    margin: 0px 0 15px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    color: var(--appTextColor) !important;
    font-weight: 700 !important;
}

.ontologySymTitle{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    color: var(--appTextColor) !important;
    font-weight: 700 !important;
    background-color: var(--backgroundColor) !important;
}

.loginFailedTitle {
    margin: 0px 0 15px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    color: #db862c !important;
    font-weight: 700 !important;
}

.accountInfo {
    float: right;
    margin: 4px 0 !important;
    text-transform: none !important;
    /* margin-top: 4px !important; */
    background-color: #db862c !important;
    border: 1px solid #ca751b !important;
    color: white !important;
}

.cancelButtonModal {
    background-color: #0182C5 !important;
    border-color: #1F4E79 !important;
    border: 1px solid #1F4E79 !important;
    color: white !important;
    float: right;
    text-transform: none !important;
}

.vl {
    border-left: 6px solid #a2a2a3;
    height: 200px;
}

.cancelButtonDisable {
    background-color: #EEEEEE !important;
    border: 1px solid #CCCCCC !important;
    float: right;
    margin: 4px 0 !important;
    text-transform: none !important;
    margin-top: 4px !important;
    color: #777777 !important;
    box-shadow: none !important;
}

.scrollMoveItem {
    height: 195px;
    overflow: scroll;
}

.scaleContent {
    float: right !important;
    margin: 10px
}

.popupFolderIcon {
    width: 3% !important;
}

.cancelButtonAI {
    background-color: #EEEEEE !important;
    border: 1px solid #CCCCCC !important;
    margin-left: -6px !important;
    text-transform: none !important;
    color: #777777 !important;
    box-shadow: none !important;
}

.cancelButtonCP {
    background-color: #EEEEEE !important;
    border: 1px solid #CCCCCC !important;
    margin: 4px;
    margin-right: 4px !important;
    margin-left: -6px !important;
    /* margin-left: 42px !important; */
    text-transform: none !important;
    color: #777777 !important;
    box-shadow: none !important;
}

.cancelButtonCPM {
    background-color: #EEEEEE !important;
    border: 1px solid #CCCCCC !important;
    /* margin-left: 42px !important; */
    text-transform: none !important;
    color: #777777 !important;
    box-shadow: none !important;
    margin-left: 18px !important;
    width: 208px !important;
}

.accountInfoCPM {
    float: right;
    margin: 4px;
    text-transform: none !important;
    margin-top: 4px !important;
    background-color: #db862c !important;
    border: 1px solid #ca751b !important;
    color: white !important;
    margin-left: 18px !important;
    width: 208px !important;
}

.accountTabs {
    margin-left: 5px;
    margin-top: 4px;
    margin-right: -4px;
    border-bottom: none;
    border-width: 1px;
    border-radius: 2px 2px 0 0;
    border-color: #CCCCCC;
    z-index: 100;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 12px;
    border: 1px solid #dcd7c0;
    background-color: var(--backgroundColor);
    min-width: 60px;
    padding: 3px 6px;
    color: var(--appTextColor)
}

.accountTabSelected {
    z-index: 150 !important;
}

.cancelButtonDisable:hover {
    box-shadow: none !important;
}

.cancelButtonClass {
    background-color: #0182C5 !important;
    border: 1px solid #1F4E79 !important;
    color: white !important;
    text-transform: capitalize !important;
}

.submitButtonClass {
    cursor: pointer;
    background-color: #db862c !important;
    border: 1px solid #ca751b !important;
    color: white !important;
    text-transform: capitalize !important;
}
.submitButtonClassDisabled {
    cursor: default;
    background-color: var(--backgroundColor) !important;
    border: 1px solid #585858 !important;
    color: var(--appTextColor) !important;
    text-transform: capitalize !important;
}

.disableButtonClass {
    background-color: #EEEEEE !important;
    border: 1px solid #CCCCCC !important;
    color: #777777 !important;
    text-transform: capitalize !important;
    cursor: default !important;
}

.loginSubmit {
    background-color: #DB862D !important;
    border: 1px solid #DB862D !important;
}

.changePassword {
    background-color: #dadbe0 !important;
}

.text-initial {
    text-transform: none !important;
}


/* .form-group{
    margin: 1.5rem;
} */

.invalid-feedback {
    margin-top: 1.5rem !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    padding-right: 0 !important;
}


/* Header */

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    padding: 0 !important;
}

.appTextFont {
    font-size: 14px !important;
}


.content>p {
    font-size: 14px !important;
    line-height: 25px;
    color: var(--appTextColor) !important;
}

.w-12 {
    width: 12%;
}

.borderless {
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-style: none;
}

.pipeText {
    margin: 0 10px;
    font-weight: 600;
}

.modalPromptContent {
    width: 85% !important;
    margin: 0 auto;
}

.rightAlign {
    text-align: right
}

.leftAlign {
    text-align: left
}


/* News and Update */

.newsUpdateContent {
    max-height: 200px;
    overflow: auto;
    padding-right: 20px;
}

.newsMainPageContent {
    max-height: 250px;
    overflow: auto;
    padding-right: 20px;
}

.MuiFormHelperText-root.Mui-error {
    width: 500px
}

.relativePosition {
    position: relative;
}

.absolutePosition {
    position: absolute;
}











.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EFEFEF;
    z-index: 35000;
    opacity: 0.7;
}

.mini-spinner-container {
    position: fixed;
    float: left;
    width: 30px;
    height: 10px;
    background-color: #efefef00;
    z-index: 35000;
    opacity: 0.7;
}


.spinner-socket {
    position: fixed;
    left: 50%;
    margin-left: -100px;
    top: 50%;
    margin-top: -100px;
    z-index: 37000;
    /* opacity: 1; */
}

.mini-spinner-socket {
    position: absolute;
    z-index: 37000;
    /* opacity: 1; */
}

.spinner-brick {
    background: #337ab7;
    width: 90px;
    height: 52px;
    position: absolute;
}

.mini-spinner-brick {
    background: #337ab7;
    width: 9px;
    height: 5px;
    position: absolute;
}

.spinner-row-2 {
    margin-top: 84px;
}

.mini-spinner-row-2 {
    margin-top: 9px;
}

.spinner-tile-1 {
    margin-left: -3px;
}

.spinner-tile-2 {
    margin-left: 93px;
    animation-delay: .1s;
    -webkit-animation-delay: .1s;
}

.mini-spinner-tile-2 {
    margin-left: 10px;
    animation-delay: .1s;
    -webkit-animation-delay: .1s;
}

.spinner-tile-3 {
    margin-left: -51px;
    animation-delay: .2s;
    -webkit-animation-delay: .2s;
}

.mini-spinner-tile-3 {
    margin-left: -6px;
    animation-delay: .2s;
    -webkit-animation-delay: .2s;
}

.spinner-tile-4 {
    margin-left: 45px;
    animation-delay: .3s;
    -webkit-animation-delay: .3s;
}

.mini-spinner-tile-4 {
    margin-left: 6px;
    animation-delay: .3s;
    -webkit-animation-delay: .3s;
}

.spinner-tile-5 {
    margin-left: 141px;
    animation-delay: .4s;
    -webkit-animation-delay: .4s;
}

.mini-spinner-tile-5 {
    margin-left: 16px;
    animation-delay: .4s;
    -webkit-animation-delay: .4s;
}

.rotate-0 {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.rotate-1 {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
}

.rotate-2 {
    transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
}

.spinner-hex {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    animation-name: spinner-pulse;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    -webkit-animation-name: spinner-pulse;
    -webkit-animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;
}

.mini-spinner-hex {
    height: 6px;
    width: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    animation-name: spinner-pulse;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    -webkit-animation-name: spinner-pulse;
    -webkit-animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes spinner-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(0.01);
        transform: scale(0.01);
    }

    35% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes spinner-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(0.01);
        transform: scale(0.01);
    }

    35% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}









/* Full Text Search */

#textareaDiv {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    background-color: white;
    background-color: -moz-field;
    border: 1px solid darkgray;
    box-shadow: 1px 1px 1px 0 lightgray inset;
    margin-top: 5px;
    padding: 2px 3px;
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    position: relative;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #616161;
    font-weight: 400;
    white-space: -moz-pre-space;
    white-space: break-spaces;
}

.fixHeight {
    height: 0;
    padding-bottom: 200px;
}

.fixWidth {
    width: 920px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.fixWidth2 {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.queryCount {
    float: right
}


#searchDiv {
    position: relative;
}

.queryError {
    border: 1px solid #db3838 !important;
}


.operatorClass {
    color: #777777;
    font-style: italic;
}

.ftLinkBlue{
    color: rgb(51, 122, 183);
    cursor: pointer;
}

.tourButtonDocManager{
    border: none;
    background: var(--backgroundColor);
    color: grey;
    font-size: 15px;
}

.simpleGrey{
    color: darkgrey
}

.simpleGQBlue{
    color:#008EC5
}

.opClass::after,
.space::after,
.query::after,
.pastequery::after,
.fieldCol::after,
.termquery::after {
    content: ' ';
}

.toClass,
.andClass,
.orClass,
.notClass {
    text-transform: uppercase;
    pointer-events: none;
    font-style: italic;
    font-weight: bold;
    color: #777777;
}


.orangeClass {
    color: #ef8d2b;
}

.greenClass {
    color: #008800;
}

.blueClass {
    color: #31708f;
}

.blueGreyClass {
    background-color: #f7f7f7;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding-left: 1px;
    padding-right: 0;
    color: #31708f;
}

.fieldquery {
    color: #3c763d;
    background-color: var(--backgroundColor);
    border: 1px solid #d6e9c6;
    transition: all 0.5s ease-in-out;
}

.searchQuery {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
}

.pub,
.publication {
    color: lightslategray;
    font-size: 14px;
}

.dropdown-content {
    display: block;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.progress-bar {
    background-color: #008EC5 !important;
}

.progress {
    border: 1px solid #008EC5 !important;
    background: #fff !important;
    border-radius: 2px !important;
    width: 100%;
}


/*new styling */

.bodyText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--appTextColor);
    font-weight: 400;
}

.bodyTextFTResult {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #777777;
    font-weight: 400;
    margin-bottom: 8px !important;
}

.pageTitle {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 22px;
    color: var(--appTextColor) !important;
    font-weight: 700;
    background-color: var(--backgroundColor);
}

.subHeading {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, !important;
    font-size: 15px !important;
    color: var(--appTextColor) !important;
    font-weight: 700 !important;
}

.headingFix {
    font-size: 22px;
    font-weight: 700 !important;
}

.heavyFont {
    font-weight: 600 !important;
}

.hrTableLines {
    border-top: 1px solid #CCCCCC;
}

.placeholderText {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #D3D3D3;
    font-weight: 400;
    font-style: italic;
}

a {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #337AB7 !important;
    font-weight: 400;
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline !important;
    cursor: pointer;
}

.ManualError {
    color: #f44336;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 13px;
}

.ManualErrorBox {
    color: #f44336;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 13px;
    border-color: #f44336;
}

.MuiFormControlLabel-label,
.MuiTypography-body1 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    color: var(--appTextColor) !important;
    font-weight: 400 !important;
}

p,
label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--appTextColor) !important;
    font-weight: 400;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.opClass {
    font-style: italic;
    font-weight: bold;
    color: #777777;
}

.query,
.termquery,
.space,
.pastequery {
    color: #000;
}

.autoquery {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    cursor: pointer;
    z-index: 10;
}

.orList {
    /* color: #ffffff; */
    background-color: #fcf8e3;
    border: 1px solid #33a2a2;
    cursor: pointer;
    z-index: 10;
}

.autoqueryUser {
    color: #31708f;
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    cursor: pointer;
    z-index: 10;
}

.popup-content {
    width: 460px !important;
}

.ce-toolbar--opened {
    display: none !important;
}

.acHeading {
    color: #888;
}

.list-inside {
    list-style: inside;
}

.errorColor {
    background-color: rgb(255, 176, 176);
}

.errorText {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-style: italic;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.71);
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.errorText:hover {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-style: italic;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.71);
    border-radius: 5px;
    background: rgba(128, 128, 128, 0.239);
    padding-left: 4px;
    padding-right: 4px;
}

.errorsPopup {
    padding: 15px;
    background-color: rgb(255, 176, 176);
}

.errorRounded {
    border-radius: 4px;
    border: solid red 2px;
}

.synonymDiv {
    /* border: 1px solid rgb(204, 204, 204); */
    resize: vertical;
    overflow: auto;
    background-color: #fff;
    height: 300px;
}

.cdx-list {
    padding-left: 12px !important;
}

.cdx-list__item {
    padding: 0 !important;
    list-style: none;
}

.loginSubmitCancel {
    background-color: #0182C5 !important;
    border-color: #1F4E79 !important;
    border: 1px solid #1F4E79 !important;
    color: white !important;
    text-transform: capitalize !important;
}

.okbuttonPostion {
    padding: 30px;
    display: flex;
    align-items: flex-end;
}

.ontologies {
    height: 100%;
    margin-top: 3px;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.loginSubmitCancel:hover {
    background-color: #0182C5 !important;
    box-shadow: none !important;
}

.loginSubmitButton {
    background-color: #db862c !important;
    margin-left: -6px;
    border-color: #ca751b !important;
    border: 1px solid #ca751b !important;
    color: white !important;
    text-transform: capitalize !important;
}

.loginSubmitButton:hover {
    background-color: #db862c !important;
    box-shadow: none !important;
}


/*Mui Radio */

.MuiRadio-colorSecondary.Mui-checked {
    color: #008EC5 !important;
}


/*Mui Checkbox */

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #008EC5 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: #008EC52B !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: #008EC52B !important;
}

.search-table .rdt_TableRow:not(:last-of-type) {
    border-bottom-width: 0px;
    background-color: var(--backgroundColor);
}
/* .MuiPopover-root {
    top: 40px !important; 
} */

/* .MuiMenu-paper {
    top: 20px !important; 
} */


/* Autocomplete Popup style */

#autocomplete {
    content: attr(placeholder);
    color: #0000005d;
    background-color: transparent;
    border: none;
}

.autocomplete {
    content: attr(placeholder);
    color: #0000005d;
    background-color: transparent;
    border: none;
}

.popup-box {
    position: fixed;
    background: #00000050;
    max-width: 50%;
    z-index: 999;
}

.ontologyFix {
    margin-top: 5px;
    margin-bottom: 0;
}

.box {
    position: relative;
    background: #fff;
    border-radius: 4px;
    padding: 0px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.checkBoxContent {
    font-size: 14px;
    text-align: initial;
    margin-left: 10px;
    margin-bottom: 10px !important;
    margin-top: 8px
}

.highlight-from-solr {
    padding: 4px;
    background-color: #fff2a7;
    font-weight: bold;
    color: #444;
}

.bold {
    font-weight: 600;
}

.clear {
    clear: both
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #337ab7 !important;
}

.MuiButton-label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


/* 
@media only screen and (max-width: 1300px) {
    .newsUpdateContent {
        padding-right: 25px;
    }
    .newsUpdateContent:hover {
        padding-right: 10px;
    }
}

@media only screen and (min-width: 1301px) {
    .newsUpdateContent {
        padding-right: 15px;
    }
    .newsUpdateContent:hover {
        padding-right: 15px;
    }
} */

.ArrowDown {
    transform: rotate(0deg);
}

.ArrowUp {
    transform: rotate(180deg);
}

.ArrowSize {
    padding-left: 8px;
    padding-right: 8px;
    width: 10px;
}

.abDelPopupHeader {
    color: #777 !important;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 0px;
    text-size-adjust: 100%;
}

.popupContainer{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    align-content: center;
    z-index: 352;
}


.popupBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* align-content: center; */
    align-items: center;
    justify-content: center;
    background-color: rgba(33, 37, 41, 0.603);
    z-index: 351;
}

.docManagerBackground{
    width: fit-content;
    height: 80%;
    background-color: white;
    border: 1px solid DarkGrey;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 600px;
}

.docManagerButton{
    text-wrap: nowrap;
    background-color: #0182C5 !important;
    border-color: #1F4E79 !important;
    border: 1px solid #1F4E79 !important;
    color: white !important;
    border-radius: 4px;
}

.ontolist{
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    background: var(--backgroundColor);
    color: var(--appTextColor);
    border-right: 1px solid darkgray;
    border-left: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    width: 220px;
    border-radius: 4px;
    height: 150px;
    overflow: scroll;
    margin-top: 178px;
}

.highlighterPanel{
    position: absolute;
    padding: 5px;
    background: white;
    width: 200px;
    border: 1px solid grey;
    border-radius: 4px;
    margin-left: -140px;
    bottom: 15px;
}

.docManagerButton:active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Apply a shadow when button is pressed */
}

.docManagerButtonDisabled{
    text-wrap: nowrap;
    background-color: lightgray !important;
    border: 1px solid grey !important;
    color: darkgray !important;
    border-radius: 4px;
    width: 95%;
}

.saveIconDocManager{
    color: #008EC5;
    cursor: pointer
}
.saveIconDocManagerDisabled{
    color: darkgray;
    cursor: default;
}

.paddingLeft5{
    padding-left: 5px;
}

.docManagerAddKeywordButton{
    width: fit-content;
    border: 2px solid gray;
    border-radius: 2px;
    padding: 0 4px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    cursor: pointer;
    background-color: var(--backgroundColor);

}

.checkbox-dark-mode {
    color: white;
}

.checkbox-dark-mode.Mui-checked {
    color: white;
}

.checkbox-light-mode {
    color: #aea9a9;
}

.checkbox-light-mode.Mui-checked {
    color: #aea9a9;
}

.checkbox-light-mode.Mui-disabled {
    color: #d3d3d3;
}

.checkbox-dark-mode.Mui-disabled {
    color: #d3d3d3;
}

.docManagerAddOntologyButton{
    width: fit-content;
    background-color: #ffba52;
    border: 2px solid grey;
    border-radius: 2px;
    padding: 0 4px 0 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    cursor: pointer;

}

.docManagerAddKeywordButton:active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Apply a shadow when button is pressed */
}

.trashbutton:active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Apply a shadow when button is pressed */
}

.kwResetButton{
    margin-top: 5px;
    color:#008EC5;
    cursor: pointer;
}

.kwResetButtonDisabled{
    margin-top: 5px;
    color: darkgray;
    cursor: default;
}

.docContainerContainerContainer{
    position: relative;
    background-color: var(--backgroundColor);
    z-index: 765;
    margin: 30px;
    border-radius: 10px;
    height: 530px
}

.docManagerX{
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 10px 0 0;
    height: 30px;
    background-color: var(--backgroundColor);
}

.docContainerContainer{
    display: flex;
    flex-direction: row;
    height: 500px;
}

.DocListContainer{
    padding: 0px 5px;
    background-color: var(--backgroundColor) !important;
    /* background-color: var(--backgroundColor) !important; */
    margin: 0 5px;
    height: 100%;
    width: auto;
    border: 1px solid DarkGrey;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 500px;
    margin-right: 0px;
}

.DocListItem{
    display: flex;
    margin: 5px;
    background-color: var(--backgroundColor);
    width: 99%;
    height: auto;
    border: 1px solid DarkGrey;
    border-radius: 4px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    min-width: 400px
}

.cancelX{
    display: flex;
    flex-direction: row;
    height: 30px;
}

.docManagerColumn{
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 5px;
}

.docManagerTitle{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.heightFix{
    height: 100%;
    margin-bottom: 70px;
}

.buttonContainer{
    display: flex;
    padding: 5px 10px;
    width: 100%;
    justify-content: center;
}

.blueLink{
    cursor:pointer;
     color:blue;
     text-decoration: none;
}

.blueLink:hover{
    text-decoration: underline;
     text-shadow: 1px 1px 1px #555;
}

.docKey{
    width: 100%;
    height: 100%;
    border: 1px solid DarkGrey;
    border-radius: 4px;
}

.docSeq{
    background-color: var(--backgroundColor);
    height: 100px;
    resize: none;
    width: 100%;
    border-radius: 4px;
    border: 1px solid DarkGrey;
}

.docListItemGetX{
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px;
    padding-right: 0;
    flex-wrap: nowrap;
    border-left: 1px solid ;
    justify-content: space-around;
    background-color: var(--backgroundColor);
}
.docListItemGetXDark{
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: auto;
    margin-right: 5px;
    margin-left: 5px; */
    padding: 5px;
    padding-right: 0;
    flex-wrap: nowrap;
    border-left: 1px solid ;
    justify-content: space-around;
    /* background-color: #292929; */
}

.docManagerInfoBlock{
    height: 100%;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5px;
    background-color: var(--backgroundColor);
}



.paddingTopFix {
    padding-top: 30px;
}

.paddingTop15pxFix {
    padding-top: 15px;

}

.historyWidthNone {
    width: 1px;
}

.groupingFix {
    width: 100px;
}

.synonymTitle {
    padding: 10px;
}


.cardUl {
    padding-left: 20px;
    padding-bottom: 0px;
    margin-bottom: 0;
}

.cardH1 {
    height: 10px;
    display: flex;
}

.ArrowLeft {
    margin-top: 100px;
    margin-right: -20px;
    transform: rotate(90deg);
    height: 30px;
    width: 100px;
    cursor: pointer;
}

.ArrowRight {
    margin-top: 100px;
    margin-left: -20px;
    transform: rotate(270deg);
    height: 30px;
    width: 100px;
    cursor: pointer;
}

.cardContainer {
    width: 500px;
    padding-top: 15px;
    min-height: 200px;
}

.cardRow {
    display: flex;
    align-content: center;
    width: 80%;
    margin-left: 92px;
    margin-right: 92px;
    margin-top: 20px;
}

.syntaxCard {
    width: 550px;
    height: 250px;
    border: 1px solid darkgray;
    color: var(--appTextColor);
    border-radius: 5px;
}

.syntaxCardInner {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.cardTopContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
}

.cardBottomContainer {
    height: 50%;
    bottom: 0;
    right: 0;
}

.marginLeftFix {
    margin-left: 0px;
}

.marginLeft10 {
    margin-left: 10px !important;
}

.fixItems {
    width: 80px;
}

.fixDate {
    width: 160px;
}

.fixQuery {
    max-width: 600px;
    overflow: scroll;
    max-height: 75px;
}

.paddingletf15 {
    padding-left: 15px;
}

.patentListTable {
    margin-left: 20px;
    margin-top: 15px;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    background-color: var(--backgroundColor);
    background-color: -moz-field;
    border: 1px solid darkgray;
    box-shadow: 1px 1px 1px 0 lightgray inset;
    padding: 2px 3px;
    width: 380px;
    height: 450px;
    border-radius: 3px;
    position: relative;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--appTextColor);
    font-weight: 400;
    white-space: -moz-pre-space;
    white-space: break-spaces;
    overflow-y: scroll;
}

.pnListPopup {
    float: left;
    width: 420px;
    height: 600px;
    position: fixed;
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    top: 50%;
    left: 50%;
    margin-top: -300px;
    margin-left: -210px;
    z-index: 1000;
}

.saveAbPopup {
    float: left;
    width: 61%;
    height: 220px;
    position: fixed;
    background-color: var(--backgroundColor);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    top: 40%;
    left: 50%;
    margin-top: -135px;
    margin-left: -428px;
    z-index: 1000;
}

.docManagerPopup{
    float: left;
    position: fixed;
    background-color: var(--backgroundColor);
    width: 900px;
    height:  700px;
    z-index: 1000;

}

.ontologyPopup {

    float: left;
    width: 700px;
    height: 550px;
    position: fixed;
    background-color: var(--backgroundColor);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    top: 50%;
    left: 50%;
    margin-top: -300px;
    margin-left: -350px;
    z-index: 1000;
}

.autoCompletePopup {

    transform-origin: 0px 0px;
    float: left;
    width: 560;
    height: 420;
    position: absolute;
    background-color: var(--backgroundColor);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    z-index: 1000;
}

.pnListBuffer {
    float: right;
    padding-top: 35px;
    padding-right: 20px;
}

.ABSaveBuffer {
    float: right;
    padding-top: 10px;
    padding-right: 20px;
}

.searchMenuList {
    padding: 20px !important
}

.modalHeader {
    border-Bottom: none !important;
    padding: 4px 4px 3px 0px;
    display: block !important;
}

.modalBody {
    margin: 0 20px 20px 20px;
    background-color: var(--backgroundColor);
    padding: 38px;
}

.titleFont {
    font-size: 20px !important
}

.really-large-font {
    font-size: 20pt;
    font-weight: bold;
}

.really-large-font-padding {
    padding: 20px 20px 0 360px;
}

.field-optgroup {
    padding-bottom: 20px;
}

.pull-right {
    float: right !important;
}


.normal-button {
    color: #FFF;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    margin: 0 0 0 10px;
    border: none;
    -webkit-appearance: none;
    outline: 0px !important;
}

.gqblue-alert {
    background-color: #db862c;
    border: solid 1px #ca751b;
}

.gqblue {
    background-color: #337ab7;
    border: solid 1px #2269a6;
}

.large-font {
    font-size: 13pt;
    font-weight: bold;
    background-color: var(--backgroundColor);
}

.filterABText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
}

.field-container-label {
    margin-bottom: 10px;
}


.field-container {
    height: 340px !important;
    width: auto;
    min-width: 300px;
    padding: 10px !important;
    border-radius: 2px;
    border-color: #CCC;
    outline: 0px !important;
    -webkit-appearance: none !important;
}

/* col select */

optgroup {
    font-weight: bold;
}

.col-select-container {
    padding: 30px;
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
}

.field-controls {
    font-size: 30pt;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
}

.field-optgroup-new {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    color: #337ab7;
}

.field-optgroup-sub {
    padding-left: 10px;
    color: #777777 !important;
    font-size: 14px;
}

.field-option {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    color: var(--appTextColor);
}

.field-option::after {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    color: black;
}

.field-option-cas {
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    color: grey;
}

.padding-top {
    padding-top: 20px;
}

.padding-left {
    padding-left: 20px;
}

.view-widget-input {
    width: 210px !important;
    border: solid 1px #CCC;
    border-radius: 4px;
    height: 33px;
    padding-left: 10px !important;
    padding-top: 8px !important;
    padding-right: 10px;
    font-size: 15px;
    font-weight: normal;
}

.autocomplete-container {
    /*position: absolute;*/
    top: 0;
    left: 0;
    display: block;
    /* background-color: #FFFFFF; */
    background-color: var(--backgroundColor);
    z-index: 760;
    min-width: 350px;
    padding: 14px;
    margin-top: 5px;
    cursor: default;
    border: solid 1px #CCC;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.clear-filter-button {
    position: absolute;
    top: 0px;
    right: 36px;
    z-index: 2000;
}

.clear-filter-relative {
    position: relative;
}

.side-menu-block {
    margin-bottom: 10px;
}

.side-menu-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.invisible-popup .arrow {
    display: none !important;
}

.invisible-popup .popover {
    width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    border: none !important;
    margin: 0px !important;
}

.search-input {
    padding: 5px 4px 5px 4px;
    margin: 0 0 4px 0;
    border-radius: 2px;
    border: solid 1px #CCC;
    -webkit-appearance: none;
    outline: 0px !important;
    width: 240px;
    height: 32px;
}

.sidebar-tour-width {
    width: 250px;
}

.autocomplete-item {
    padding: 4px;
    white-space: nowrap;
    color: var(--appTextColor);
}

.autocomplete-item-color {
    padding: 4px;
    white-space: nowrap;
    width: 30px;
    height: 15px;
    color: var(--appTextColor);
}

.autocomplete-item:hover {
    background-color: #337ab7;
    color: #ffffff;
}

.autocomplete-spacer {
    height: 10px;
    width: 100%;
    border-bottom: solid 1px #CCC;
    margin-bottom: 10px;
    padding-top: 10px;
}

.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.cursor-default {
    cursor: default;
}

.side-menu-sub-items {
    padding-bottom: 2px;
    padding-left: 20px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #26262600;
}

.side-menu-sub-items-visible {
    padding-bottom: 2px;
    padding-left: 20px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: visible;
    white-space: nowrap;
}

.side-menu-sub-items:hover {
    overflow: visible;
    white-space: normal;
}

.side-menu-sub-sub-items {
    padding-bottom: 2px;
    padding-left: 5px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.side-menu-sub-sub-items:hover {
    overflow: visible;
    white-space: normal;
}

.color-select-div {
    padding-right: 3px;
    padding-left: 3px;
    cursor: pointer;
}

.pull-left {
    float: left !important;
}

.side-menu-item-hl {
    padding-left: 10px;
    position: relative;
    cursor: default;
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.side-menu-indent {
    padding-left: 0px;
    margin-bottom: 5px;
    margin-top: 2px;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.color-select-highlight {
    width: 20px;
    border: solid 1px #999;
    border-radius: 3px;
    height: 16px;
    margin-top: 2px;
}

/* colors for highlight of text */
.highlight-FFEB3B {
    background-color: #FFEB3B;
}

.highlight-80CBC4 {
    background-color: #80CBC4;
}

.highlight-B39DDB {
    background-color: #B39DDB;
}

.highlight-EF9A9A {
    background-color: #EF9A9A;
}

.highlight-F48FB1 {
    background-color: #F48FB1;
}

.highlight-81D4FA {
    background-color: #81D4FA;
}

.highlight-A5D6A7 {
    background-color: #A5D6A7;
}

.highlight-E6EE9C {
    background-color: #E6EE9C;
}

.highlight-FFF59D {
    background-color: #FFF59D;
}

.highlight-FFE082 {
    background-color: #FFE082;
}

.highlight-FFCC80 {
    background-color: #FFCC80;
}

.highlight-FFAB91 {
    background-color: #FFAB91;
}

.highlight-FF7043 {
    background-color: #FF7043;
}

.highlight-FFA726 {
    background-color: #FFA726;
}

.highlight-BCAAA4 {
    background-color: #BCAAA4;
}

.highlight-B0BEC5 {
    background-color: #B0BEC5;
}

.color-box-container {
    background-color: #FFF;
    border: solid 1px #CCC;
    padding: 8px;
    margin-top: -40px;
    margin-left: 25px;
    width: 221px;
    position: absolute;
    z-index: 500;
}

.color-box {
    width: 25px;
    height: 25px;
    border: solid 1px #CCC;
    margin: 2px;
}

.button-div {
    padding-top: 15px;
    padding-bottom: 10px;
    font-weight: 400;
}

.small-button {
    font-size: 9pt;
    color: #FFF;
    border-radius: 2px;
    padding: 6px 16px 6px 16px;
    margin: 0 5px 0 0;
    border: none;
    -webkit-appearance: none;
    outline: 0px !important;
}

.grey {
    background-color: #EEE !important;
    color: #777 !important;
    border: solid 1px #CCC;
}

.btn-dropdown-label {
    font-weight: normal;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    /*color: #555 !important;*/
}

/* charts */

.chart-thumb {
    border: solid 1px #CCC;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 140px;
    height: 112px;
    background-color: var(--backgroundColor);
}

.chart-thumb-sel {
    border: solid 1px #CCC;
    background-color: var(--backgroundColor);
}

.chart-thumb-container {
    margin-right: 10px;
}

.view-container {
    /*position: absolute;
    z-index: 760;*/
    padding: 14px;
    /* margin-top: 5px; */
    top: 0;
    left: 0;
    display: block;
    /* background-color: #FFFFFF; */
    background-color: var(--backgroundColor);
    min-width: 300px;
    cursor: default;
    border: solid 1px #CCC;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.sort-container {
    color: #777777;
    /*margin-top: 5px;*/
    top: 100%;
    left: 0;
    display: block;
    /* background-color: #fff; */
    min-width: 160px;
    cursor: default;
    /*border: solid 1px #CCC;*/
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
    font-size: 14px;
    text-align: left;
    z-index: 1000;
    padding: 10px 15px;
    margin: -8px 0 -8px;
    float: left;
    border: 1px solid rgba(0, 0, 0, .15);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    list-style: none;
}

::-webkit-input-placeholder {
    font-style: italic;
    color: lightgrey;
}

:-moz-placeholder {
    font-style: italic;
    color: lightgrey;
}

::-moz-placeholder {
    font-style: italic;
    color: lightgrey;
}

:-ms-input-placeholder {
    font-style: italic;
    color: lightgrey;
}

/*tab under toolbar*/
.arrow_box {
    padding: 30px 20px 30px 20px;
    margin-top: 20px;
    width: 1050px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #ccc;
}

.arrow_box:after,
.arrow_box:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    left: 60px;
}

.toolbar-button {
    min-width: 30px;
    margin-left: 5px;
    color: var(--appTextColor) !important;
    background-color: var(--backgroundColor);
}

.arrow_box:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #FFF;
    border-width: 14px;
    margin-left: -14px;
}

.arrow_box:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: #CCC;
    border-width: 16px;
    margin-left: -16px;
}


.gqblue-disabled {
    background-color: #EEE !important;
    border: solid 1px #CCC;
}

.query-stats-left {
    width: 230px;
}

.header-font {
    font-size: 14pt !important;
    vertical-align: middle;
    font-weight: bold;
}

.top-spacer {
    padding-top: 15px;
    margin-top: 15px;
    border-top: solid 1px #CCC;
}

.button-div-pad {
    padding-left: 15px;
}

textarea.pnlist {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--appTextColor) !important;
    width: 215px;
    height: 100px;
    -webkit-appearance: none;
    outline: 0px !important;
    resize: none;
    margin-top: 4px;
    border: solid 1px #CCC;
    border-radius: 2px;
    padding: 5px;
    background-color: var(--backgroundColor);
}

.subMenuHeading {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, !important;
    font-size: 15px !important;
    color: var(--appTextColor) !important;
    background-color: var(--backgroundColor) !important;
    /* box-shadow: '0 0 5px white'; */
    /*font-weight: 700 !important;*/
}

.fa-star-mid {
    display: inline-block;
    vertical-align: middle;
}

.color-example {
    min-width: 25px;
    min-height: 15px;
    margin-top: 3px;
    margin-right: 5px;
    margin-left: 5px;
    border: solid 1px #CCC;
}

.color-0 {
    /* white */
    background-color: rgba(235, 232, 232, 0.678) !important;
}

.color-1 {
    /* red */
    background-color: red !important;
}

.color-2 {
    /* orange */
    background-color: orange !important;
}

.color-3 {
    /* yellow */
    background-color: yellow !important;
}

.color-4 {
    /* green */
    background-color: green !important;
}

.color-5 {
    /* blue */
    background-color: #337ab7 !important;
}

.color-6 {
    /* purple */
    background-color: purple !important;
}
.color-7 {
    /* Gray */
    background-color: gray !important;
}
.color-8 {
    /* Tan */
    background-color: tan !important;
}
.color-9 {
    /* Light Orange */
    background-color: #fed8b1 !important;
}
.color-10 {
    /* Light Yellow */
    background-color: #eedc82 !important;
}
.color-11 {
    /* Light Green */
    background-color: #98ff98 !important;
}
.color-12 {
    /* Light Blue */
    background-color: #87ceff !important;
}
.color-13 {
    /* Violet */
    background-color: #ee82ee !important;
}

.large-button {
    color: #FFF;
    border-radius: 2px;
    padding: 12px 20px 12px 20px;
    margin: 0 0 0 10px;
    border: solid 1px #CCC;
    -webkit-appearance: none;
    outline: 0px !important;
    font-size: 12pt;
}

.jumbotron {
    padding: 10px;
    margin-bottom: 10px;
    background-color: rgba(238, 238, 238) !important;
    /* background-color: var(--backgroundColor) !important */
    /*background-color: #eeeeee;gainsboro*/
}

.jumbotronDark {
    padding: 10px;
    margin-bottom: 10px;
    background-color: rgba(238, 238, 238, 0.12) !important;
}

.jumbotron p {
    font-size: 21px;
}

.ABjumbotron {
    padding: 10px;
    margin: 12px;
    border-radius: 2px;
    height: 360px;
    background-color: var(--backgroundColor) !important;
    margin-top: 22px;
    /*background-color: #eeeeee;gainsboro*/
}

.abHeight140 {
    height: 140px !important
}

.abHeight155 {
    height: 155px !important
}

.ABSavejumbotron {
    padding: 10px;
    margin: 12px;
    border-radius: 2px;
    height: 165px;
    background-color: #56545473 !important;
    /*background-color: #eeeeee;gainsboro*/
}

.ABSaveSubmittedJumbotron {
    padding: 10px;
    margin: 12px;
    border-radius: 2px;
    height: 140px;
    background-color: var(--backgroundColor) !important;
    color: var(--appTextColor) !important;
    margin-top: 22px;
    /*background-color: #eeeeee;gainsboro*/
}

.abInput {
    width: 100%;
}

.gq-tooltip {
    opacity: 1 !important;
}

.gq-tooltip-right {
    border-right-color: #777 !important;
}

.gq-tooltip-error {
    background-color: #DDD;
    color: #F00;
}

.dbsetting-container {
    height: 200px !important;
    width: auto;
    min-width: 500px;
    padding: 10px !important;
    border-radius: 2px;
    border-color: #CCC;
    outline: 0px !important;
    -webkit-appearance: none !important;
    background-color: var(--backgroundColor);
    color: var(--appTextColor)
}

.side-menu-item {
    padding-left: 15px;
    cursor: default;
    margin-top: 2px;
    position: relative;
}

.side-menu-span {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.filter-no-wrap {
    white-space: nowrap;
    display: list-item;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alignCenter {
    align-content: center;
}

.filter-no-wrap:hover {
    margin-left: 15px;
    width: fit-content;
    padding-left: 3px;
    padding-right: 3px;
    background-color: var(--backgroundColor);
    border: 1px solid grey;
    white-space: nowrap;
    z-index: 100;
}

.big-message {
    padding: 20px 20px 0 20px;
}

.filter-widget-container {
    padding: 30px 10px 20px 10px;
}

.filter-widget-field {
    width: 180px;
    padding-right: 10px;
}

.filter-widget-method {
    padding-right: 10px;
    width: 280px !important;
}

.filter-widget-field-middle {
    height: 33px;
    line-height: 33px;
}

.filter-widget-operator {
    width: 100px;
    padding-right: 10px;
}

.filter-widget-position {
    width: 120px;
    padding-right: 10px;
}

.filter-widget-value {
    padding-right: 10px;
    width: 200px;
}

.filter-widget-input {
    width: 100%;
    border: solid 1px #CCC;
    border-radius: 4px;
    height: 33px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
}

.filter-widget-textarea {
    width: 100%;
    border: solid 1px #CCC;
    border-radius: 4px;
    padding: 6px 10px 0 10px;
    resize: none;
    min-height: 33px;
    margin: 0px !important;
    display: block;
    /* background-color: var(--backgroundColor);
    color: var(--appTextColor); */
}

.filter-widget-label {
    font-weight: normal;
    padding-top: 10px;
    padding-right: 10px;
}

.filter-widget-input:focus,
.filter-widget-textarea:focus {
    -webkit-appearance: none !important;
    outline: 0px !important;
    border: solid 1px #CCC;
}

.filter-widget-bottom {
    padding-top: 40px;
}

.rTable {
    display: block;
}

/*table*/
/*.rTableRow { display: table-row; }*/
.rTableRow {
    display: table;
    width: 100%;
}

.rTableRow.colspan {
    display: block;
}

.rTableCell,
.rTableHead {
    display: table-cell;
}

.control-spacer {
    margin-top: 5px;
}

.filter-widget-filter-method {
    word-wrap: break-word;
    white-space: normal;
    display: inline-block;
}

.filter-widget-group-item {
    margin-top: 4px;
    padding: 3px 10px 0px 10px;
    cursor: default;
    white-space: nowrap;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--appTextColor);
}

.filter-widget-menu-item {
    padding: 1px 20px 1px 20px !important;
    cursor: default;
    white-space: nowrap;
    font-size: 14px !important;
    color: #777 !important;
}

.filter-widget-menu-item:hover {
    background-color: #F0F0F0;
}

.filter-widget-menu-item-color {
    padding: 1px 5px 1px 5px;
    cursor: default;
    white-space: nowrap;
}

.filter-widget-menu-item-color:hover {
    background-color: #F0F0F0;
}

.filter-widget-menu-item:disabled {
    font-style: italic;
    color: #F0F0F0;
}

.filter-widget-options {
    padding-right: 10px;
    width: 280px;
}

.btn-dropdown {
    font-size: 10pt;
    color: var(--appTextColor);
    background-color: var(--backgroundColor) !important;
    padding-top: 5px !important;
    padding-bottom: 6px !important;
    padding-left: 10px !important;
    text-align: left !important;
    border: solid 1px #CCC !important;
    border-radius: 4px !important;
}

.colo .dropdown-menu {
    padding: 15px;
}

.dropdown-menu-color {

    padding: 5px 5px 5px 5px;
    min-width: 110px;
    width: 110px;
    left: -50%;
}

.dropdown-menu-dates {
    min-width: 210px !important;
    padding: 5px;
    max-height: 400px;
    overflow-y: scroll;
}

.btn-dropdown-methods {
    min-width: 280px !important;
}

/* force the scrollbar to be visible at all times in OSX */
.dropdown-menu-dates::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.dropdown-menu-dates::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.dropdown-menu a {
    color: #777 !important;
    text-decoration: none;
    cursor: default !important;
}

.dropdown-menu a:focus {
    -webkit-appearance: none !important;
    outline: 0px !important;
    background-color: #337ab7 !important;
    color: #FFFFFF !important;
}

.btn-dropdown:focus,
.dropdown-toggle {
    box-shadow: none !important;
    color: #777 !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.btn-dropdown:hover {
    color: #777 !important;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.sortdiv {
    color: #777 !important;
}

.dropdown-menu-options {
    min-width: 280px !important;
}

.filter-widget-adddel {
    padding-right: 5px;
    min-width: 33px;
    padding-top: 5px;
}

.plus-min-button {
    cursor: default;
    font-size: 15px;
    line-height: 15px;
    padding-top: 6px;
    text-align: center;
    padding-left: 1px;
    height: 33px;
    width: 33px;
    border: solid 1px #CCC;
    border-radius: 4px;
    background-color: #EFEFEF;
}

.floating-action-button-block {
    position: fixed;
    left: 4em;
    bottom: 2em;
    z-index: 1;
}

.filter-widget-top {
    padding-bottom: 20px;
}

.custom-query-error {
    color: red;
    font-weight: bold;
}

.filter-widget-atom-index {
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
    font-size: 15px;
}

.green-font {
    color: #98C747;
}

.red-font {
    color: red;
}

.btn-dropdown-dates {
    width: 250px !important;
}

.width-40 {
    width: 41%;
}

.width-20 {
    width: 18%;
    text-align: center;
    padding-top: 10px;
    color: #777777;
    font-weight: 400;
}

.patent-date-picker1 {
    width: 200px;
    height: 32px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #777777;
    font-weight: 400;
    position: relative; 
    top: 10px ;
    left:auto
}

.patent-date-picker {
    width: 200px;
    height: 36px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #777777;
    font-weight: 400;
}

.small-patent-date-picker {
    width: 82px;
    height: 36px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #777777;
    font-weight: 400;
}

.filter-widget-input-textarea {
    width: 100%;
    border: solid 1px #CCC;
    border-radius: 4px;
    padding: 6px 10px 7px 10px;
    resize: none;
    min-height: 33px;
    margin: 0px !important;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #777777;
    font-weight: 400;
    background-color: var(--backgroundColor);
}

.custom-textarea {
    margin-top: 14px !important;
}

.custom-filter-no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    /*max-width: 250px;*/
}

.custom-filter-no-wrap:hover {
    /*border: 1px solid grey;*/
    white-space: break-spaces;
    overflow: visible;
}

.custom-filter-label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    /* color: #777777; */
    font-weight: 400;
    background-color: var(--backgroundColor);
}

.tour-arrow {
    position: absolute;
    /* display: block; */
    width: -29px;
    /* height: 0; */
    border-color: transparent;
    border-style: solid;
    border-width: 11px;
    /* top: 11%; */
    left: -11px;
    margin-top: 55px;
    border-right-color: #337ab7;
    border-left-width: 0;
    margin-left: -11px;
}

.element {
    position: absolute;
    /* z-index: 1; */
    padding: 9px 14px;
    line-break: auto;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    color: #FFF !important;
    font-size: 14pt;
    text-align: center;
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    border-radius: 6px;
    line-height: 1.428571;
    margin-right: -51px;
}

.tour-step-navigation {
    text-align: right;
    padding-top: 15px;
}


.prevBtn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    color: #333;
    background-color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
}

.pauseBtn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-left: ridge;
}

.pauseBtnFor12 {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
}

.prevBtnFor1 {
    /*border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;*/
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    color: #333;
    background-color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
}

.nextBtn {
    padding: 5px 10px;
    font-size: 12px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.endTour {
    padding: 5px 10px;
    font-size: 12px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: 3px;
}

.rhombus {
    position: absolute;
    width: 70px;
    height: 70px;
    background-color: #337ab7;
    transform: rotate(45deg);
}

.tourContainer{
    display: flex;
    position: absolute;
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    border-radius: 6px;
    padding: 10px;
    width: 300px;
    /* height: 130px; */
    flex-direction: column;
    z-index: 500;
}

.close-button {
    cursor: pointer;
    margin-left: auto; /* Push the close button to the right */
} 
.btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 3px;
    top: 2px;
}

.resizable-column {
    position: relative;
    overflow-x: hidden;
    height: 100%;
}

.resizer {
    position: absolute;
    top: 0;
    right: -5px;
    width: 10px;
    height: 100%;
    cursor: col-resize;
    z-index: 1;
}

.resizer:hover {
    background-color: #f1f1f1;
}

.nextBtn:hover,
.prevBtn:hover,
.pauseBtn:hover,
.endTour:hover,
.pauseBtnFor12:hover,
.prevBtnFor1:hover {
    background-color: #f1f1f1;
}

.color-select {
    width: 30px;
    border: solid 1px #CCC;
    height: 16px;
    margin-top: 2px;
    background-color: #036f9f;
}

.color-box-select {
    background-color: #FFF;
    border: solid 1px #CCC;
    padding: 8px;
    margin-top: -20px;
    margin-left: 35px;
    width: 221px;
    position: absolute;
}


.els-container {
    height: 150px !important;
    width: auto;
    min-width: 320px;
    padding: 10px !important;
    border-radius: 2px;
    border-color: #CCC;
    outline: 0px !important;
    -webkit-appearance: none !important;
    background-color: (var(--backgroundColor));
}

.els-controls {
    font-size: 20pt;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
}

.btn:focus {
    outline: none;
    box-shadow: none !important;
}

.input-with-error {
    border: solid 1px red !important;
    border-radius: 4px;
}

.dragResize {
    cursor: default;
    border-right: none;
}

.dragResize:hover {
    cursor: ew-resize;
    border-right: 3px solid rgb(136, 136, 136);
}

.text-notes {
    display: inline-block;
    float: left;
    width: 400px;
    word-wrap: break-word;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    color: #777777 !important;
    font-weight: 700 !important;
}

.lva-hint {
    cursor: pointer;
}

.lva-bar {
    display: inline-block;
    position: relative;
}

.lva-bar.lva-right-end {
    display: inline-block;
    position: relative;
}

.lva-bar.lva-left-end {
    display: inline-block;
    position: relative;
}

.lva-bar.lva-no-ends {
    display: inline-block;
    position: relative;
}

.lva-bar .lva-markers {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(0.4em) scaleY(2);
}

.lva-bar.lva-red {
    display: inline-block;
    border-top: 5px solid #c74848;
    color: #c74848;
    position: relative;
}

.lva-bar.lva-orange {
    display: inline-block;
    border-top: 5px solid #db862c;
    color: #db862c;
    position: relative;
}

.lva-bar.lva-yellow {
    display: inline-block;
    border-top: 5px solid #ebd023;
    color: #ebd023;
    position: relative;
}

.lva-bar.lva-green {
    display: inline-block;
    border-top: 5px solid #98C747;
    color: #98C747;
    position: relative;
}

.lva-bar.lva-blue {
    display: inline-block;
    border-top: 5px solid #337ab7;
    color: #337ab7;
    position: relative;
}

.lva-bar.lva-purple {
    display: inline-block;
    border-top: 5px solid #a843a8;
    color: #a843a8;
    position: relative;
}

.lva-bar.lva-bg-white {
    background-color: transparent
        /* #fff */
    ;
}

.lva-bar .tooltiptext {
    visibility: hidden;
    background-color: #777;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px 6px 6px;
    box-shadow: 0px 1px 7px #888;

    font-size: 7pt;
    font-family: Arial, sans-serif;

    /* Position the tooltip */
    position: absolute;
    bottom: 10%;
    left: 100%;
    margin-left: 10px;
    z-index: 9999;

    overflow: visible;
}

.lva-bar .tooltiptext:before {
    content: '';
    position: absolute;

    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #777;
    left: -6px;
}

.lva-bar:hover .tooltiptext {
    visibility: visible;
}

.lva-bar .singletooltiptext {
    visibility: hidden;
    background-color: #777;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px 6px 6px;
    box-shadow: 0px 1px 7px #888;

    font-size: 7pt;
    font-family: Arial, Helvetica, sans-serif;

    /* Position the tooltip */
    position: absolute;
    bottom: 10%;
    margin-left: 2px;
    z-index: 10001;
}

.lva-bar .singletooltiptext:before {
    content: '';
    position: absolute;

    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #777;
    left: -6px;
}

.lva-bar span:hover .singletooltiptext {
    visibility: visible;
}

.lva-bar span:hover~.tooltiptext {
    visibility: hidden;
}

.ali-pre {
    font-size: 9pt !important;
    font-family: "Courier New", monospace !important;
    /* font-family: "Console", monospace !important; */
    border: none !important;
    /* background-color: #FFF !important; */
    padding: 0 !important;
    margin: 0 !important;
    color: var(--appTextColor);
}

.lva-marked {
    background-color: #337ab7;
    color: white;
}

.lva-not-exact-match {
    text-transform: lowercase;
}

.lvm-summary-row {
    white-space: nowrap;
}

.lvm-summary-header-row {
    width: 600px;
    text-align: center;
    margin-bottom: 10px;

    /*font-size: 9pt !important;*/
    font-weight: bold;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.lvm-summary-header-label {
    width: 400px;
}

.lvm-summary-link {
    position: relative;
    margin-right: 4px;
    margin-left: 4px;
    left: 450px;
}

.lvm-summary-link-label {
    float: left;
    vertical-align: middle;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.lvm-summary-gqcol {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
    margin-left: 4px;
    display: inline-block;
    float: left;
    width: 104px;

    /*font-size: 9pt !important;*/
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.lvm-summary-gqcol:hover {
    overflow: visible;
    white-space: normal;
}

.lvm-summary-sub-header {
    float: left;

    /*font-size: 9pt !important;*/
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.mismatch {
    background-color: yellow;
}

.match {
    background-color: orange;
}

.lva-color-box-select {
    background-color: #FFF;
    border: solid 1px #CCC;
    padding: 8px;
    margin-top: -10px;
    margin-left: 25px;
    width: 195px;
    position: absolute;
}

.cross {
    margin-left: 845px;
    position: absolute;
    right: 10px;
    width: 28px;
    height: 28px;
    line-height: 25px;
    font-size: 20px;
    text-align: center;
    color: #666;
    background-color: #fff;
    top: 10px;
}

.cancelBtnClass {
    border-radius: inherit;
    background-color: #EEE !important;
    color: #777 !important;
    border: solid 1px #CCC;

}

.createReportClass {
    background-color: #337ab7;
    border: solid 1px #2269a6;
}

.variationClass {
    font-size: 21px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: left;
}

.tagSize {
    font-size: 63px;
}

.toolbar {
    justify-content: flex-start;
    padding: 4px;
    background-color: var(--backgroundColor);
    border-bottom: 1px solid #ccc;
    margin-top: 20px;
}

.search-bar {
    margin-top: -27px;
    margin-right: 25px;
    flex: 0;
}

.tableCSS {
    margin-top: 40px;
    border: 1px solid #dcd7c0;
    overflow-x: auto;
}

.resulttable_head {
    background-color: #036f9f;
    color: white;
    padding: '4px';
    text-align: center;
    vertical-align: center;
    font-weight: normal;
    border-left: 1px solid white;
}

.mTeamsBtn {
    border: #eeece1;
    background: var(--backgroundColor);
    color: var(--appTextColor);
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 12px;
}

.mTeamsBtn:hover {
    border: #CCCCCC solid;
    border-width: 1px;
    border-radius: 2px;
}

.mTSearch {
    border: #eeece1;
    font-family: Verdana, Helvetica, sans-serif;
    background-color: #eeece1;
    font-size: 12px;
    margin-top: 26px;
}

.mTSearch:hover {
    border: #CCCCCC solid;
    border-width: 1px;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 0;
}

.columnNo {
    padding: 0 0.3em;
    text-align: center;
    margin-right: 12px;
    color: gray;
    border: none;
    background-color: var(--backgroundColor);
}

.columnNo:hover{
    border: #CCCCCC solid;
}
.colNoCSS {
    margin-left: 6px;
    padding: 0 0.3em;
    text-align: center;
    margin-right: 6px;
}

.columnLink {
    color: white !important;
    font-weight: 500;
}

.titlebar {
    /* border: 1px solid var(--backgroundColor); */
    border-top: none;
    /* background-color: var(--backgroundColor); */
    padding: 5px 7px 4px 7px;
}

.table_body {
    border-top: 1px solid #dcd7c0;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 12px;
    vertical-align: top;
    height: 42px;
}

.table_body:hover {
    background-color: var(--backgroundColor);
    height: 42px;
}

.formlayout {
    margin-bottom: 10px;
    border: 0px solid #000000;
}

.deletedialogtextteam {
    margin-top: 0.5em;
    font-family: Verdana, Helvetica, sans-serif;
    margin-bottom: 12px;
    font-size: 12px;
    color: black;
    padding: 12px;
}

/* .okButton{
    border: 1px solid #b5b5b5;
    padding: -1px 4px 4px 4px;
    border-radius: 4px;
    background-color: #e6e6e6;
    float: left;
  } */
.membersStyle {
    display: flex;
}

.searchBar {
    flex: 1;
}

.mToolbar {
    border-right: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
}

.mt_container {
    padding: 25px;
    margin-top: -3px;
    border-left: 1px solid #CCCCCC;
    margin-left: 12px;
    margin-bottom: 16px;
}

.mt_layout {
    border-right: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
}

.className {
    padding: 5px 5px 5px 0px;
}

.modalContent {
    width: 90% !important;
}

.modalSizeforSm {
    width: 120% !important;
}

.inactiveTab {
    margin-left: 5px;
    margin-top: 4px;
    margin-right: -4px;
    border-width: 1px;
    border-radius: 2px 2px 0 0;
    border-color: #CCCCCC;
    z-index: 100;
    font-family: verdana, helvetica;
    border: 1px solid #dcd7c0;
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
    font-size: 12px;
    padding-top: 9px;
    min-width: 60px;
    border-bottom: white;
}

.form-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1px;
}

.DMTBtn {
    margin-top: -7px;
    margin-bottom: 12px;
    margin-right: 12px;
    min-width: 35px;
    font-size: 13px;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    background-color: #e6e6e6;
}

.label {
    color: black !important;
    /* color: var(--appTextColor); */
    outline: 0px !important;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 11px;
    padding: 0px 14px 0px 0px;
}
.teamsLabel {
    color: var(--appTextColor);
    outline: 0px !important;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 11px;
    padding: 0px 14px 0px 0px;
}
.innerTables {
    vertical-align: top;
    border: 1px solid #b5bcc7;
    background-color: #f7fcff;
    max-width: 157px;
}

.innerTables:hover {
    background-color: rgb(214, 227, 239);
}


.pagingContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.pagingArrow {
    display: flex;
    height: 100%;
    width: 35px !important;
    padding: 0 5px 0 5px;
    color: #008EC5;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pagingArrowDisabled {
    color: darkgray;
    display: flex;
    height: 100%;
    width: 35px !important;
    padding: 0 5px 0 5px;
    cursor: default;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pagingNumber {
    display: flex;
    height: 100%;
    padding: 0 5px 0 5px;
    color: #008EC5;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pagingNumberDisabled {
    color: darkgray;
    display: flex;
    height: 100%;
    padding: 0 5px 0 5px;
    cursor: default;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.selected-row {
    background-color: #bee5f2;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 12px;
    color: black
}

.inactiveManageTeams {
    margin-left: 5px;
    margin-top: 4px;
    margin-right: -4px;
    border-width: 1px;
    border-radius: 2px 2px 0 0;
    border-color: #CCCCCC;
    z-index: 100;
    font-family: verdana, helvetica;
    background-color: #036f9f;
    /* background-color: var(--appTextColor); */
    font-size: 12px;
    padding-top: 9px;
    min-width: 60px;
    padding-bottom: 4px;
    border-right: 1px solid #dcd7c0;
    border-bottom:2px white !important;
    color: white
}
.activeManageTeams {
    margin-left: 5px;
    margin-top: 4px;
    margin-right: -4px;
    border-bottom: none;
    border-width: 1px;
    border-radius: 2px 2px 0 0;
    border-color: #CCCCCC;
    z-index: 100;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 12px;
    border: 1px solid #dcd7c0;
    background-color: #036f9f;
    min-width: 60px;
    padding: 3px 6px;
    color: white
}
.pnTextData{
    font-Family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-Size: '12px'; 
    margin-Left: '5px';
    color: var(--appTextColor);
}

.uploadTextData{
    display: 'table-cell';
    font-Size: '15px';
    color: var(--appTextColor)
}
.projectList{
    background-color: var(--backgroundColor);
}

.noWrap{
    white-space: "nowrap";
    padding: '2px';
    overflow: "hidden";
    text-overflow: 'ellipsis';
    z-Index: "351 !important";
    background-color: var(--backgroundColor);
}
.groupBig-message{
    padding: 0px 20px 0 20px;
}
.min-Width{
    min-width: 60px !important; 
}
.greyAdvanced{
    color: #777;
}
.greyAdvancedDark{
    color: #fff;
}
.clustal-pre pre {
    font-size: 9pt !important;
    font-family: monospace, Courier, "Courier New" !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
}

.single-line-container {
    display: flex;
    align-items: center;
    gap: 12px; /* Optional for consistent spacing */
}

/* .custom-checkbox:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    color: inherit !important;
  } */

  .black-text {
    color: black !important;
}
