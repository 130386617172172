:root {
  --backgroundColor: #000000 ; /* default light theme background color */
  --appTextColor: #777; /* default light theme text color */
}


/* Use variables throughout your CSS */
body {
  background-color: var(--backgroundColor);
  color: var(--appTextColor);

}

.navbar-brand>img {
  padding-top: 11px;
  width: 130px;
  margin-left: 60px;
}
.navbar-brand {
    height: auto;
    margin: 0;
    padding: 0;
    margin-right: 20px;
}
.navbar-default{
color: var(--appTextColor);
background-color: #2F4F4F;
border-color: var(--backgroundColor);
}
.navbar-default .navbar-nav > li > a{
	color: var(--appTextColor);
}
.navbar-default .navbar-nav > .dropdown > a .caret{
	border-top-color: var(--backgroundColor);
    border-bottom-color: var(--backgroundColor);
}
.navbar-default .navbar-brand{
	color: var(--backgroundColor);
}
.menu-large {
  position: static !important;
}
.megamenu{
  padding: 20px 0px;
  width:100%;
  max-height:400px;
  overflow-x: scroll;
}
.megamenu> li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.megamenu> li > ul > li {
  list-style: none;
}
.megamenu> li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.2em;
  white-space: normal;
  font-size: 14px !important;
  color: #4a5050 !important;
  margin: 0 7px 0 17px;
}

.megamenu> li ul > li > a:hover,
.megamenu> li ul > li > a:focus {
  text-decoration: none;
  color: var(--appTextColor);
  background-color: var(--backgroundColor);
}
.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: var(--appTextColor);
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #00A7E8;
}
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
 .megamenu> li > span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.2em;
  white-space: normal;
  font-size: 14px !important;
  color: #4a5050 !important;
  margin: 0 7px 0 0px;
  cursor: pointer;
}

.megamenu.dropdown-header {
  color: #428bca;
  font-size: 18px;
}
@media (max-width: 768px) {
  .megamenu{
    margin-left: 0 ;
    margin-right: 0 ;
  }
  .megamenu> li {
    margin-bottom: 30px;
  }
  .megamenu> li:last-child {
    margin-bottom: 0;
  }
  .megamenu.dropdown-header {
    padding: 3px 15px !important;
  }
  .navbar-nav .open .dropdown-menu .dropdown-header{
	color:var(--appTextColor);
  }
}