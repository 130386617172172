:root {
    --backgroundColor: #000000 ; /* default light theme background color */
    --appTextColor: #777; /* default light theme text color */
  }
  
  
  /* Use variables throughout your CSS */
  body {
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
  
  } 
  
  .detail-container {
    padding: 20px 30px 20px 30px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    border: solid 1px #CCC;
    color: var(--appTextColor);
    max-width: 900px;
    overflow-y: auto;
  }
  
  pre {
    font-size: 9pt !important;
    font-family: "Courier New", monospace !important;
    /* font-family: "Console", monospace !important; */
    border: none !important;
    background-color: var(--backgroundColor) !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  
  /* .clearfix{*zoom:1;}.clearfix:before,.clearfix:after{display:table;content:"";line-height:0;}
  .clearfix() {
    &:before,
    &:after {
      content: " "; // 1
      display: table; // 2
    }
    &:after {
      clear: both;
    }
  } */
  .pull-right {
    float: right !important;
  }
  .pull-left {
    float: left !important;
  }
  .details-header {
    text-align: right;
  }
  .details:last-of-type table.detail-spacer {
    /* background-color: yellow; */
    border-bottom: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .detail-paragraph {
    padding-bottom: 10px;
    color: var(--appTextColor);
  }
  
  .detail-field {
    font-weight: bold;
    color: var(--appTextColor);
  }
  
  .detail-header {
    font-size: 16pt;
    font-weight: bold;
    padding-bottom: 10px;
    color: var(--appTextColor);
  }
  .detail-label {
    width: 220px;
    font-weight: bold;
    padding-right: 10px;
    color: var(--appTextColor);
  }
  
  .detail-spacer {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #CCC;
    width: 100%;
    color: var(--appTextColor)
  }
  
  .detail-spacer-alignment {
    padding-bottom: 30px;
    margin-bottom: 15px;
    border-bottom: solid 1px #CCC;
    width: 100%;
    margin-top: 30px;
  }
  
  .detail-spacer td {
    padding-bottom: 15px;
    color: var(--appTextColor);
  }
  .pad-menu-item {
    margin-left: 15px;
    padding-left: 15px;
  }
  .sep-menu-item {
    border-style: solid;
    border-color: var(--appTextColor);
    border-width: 0 0 0 1px;
  }
  .field-header {
    margin: 5px 0 10px 0px;
  }
  .detail-label-sub {
    width: 220px;
    padding-right: 10px;
    padding-left: 0px;
  }
  table.classification tr td {
    padding-bottom: 4px;
    padding-right: 30px;
  }
  .side-label {
    width: 200px;
  }
  .detail-label-no-width {
    font-weight: bold;
    padding-right: 10px;
    color: var(--appTextColor);
  }
  .expand-close-right {
    float: right;
    font-weight: normal;
  }
  .not-available {
    font-style: italic;
    color: #CCC;
  }
  .black-font {
    color: #555 !important;
    padding: 0px 3px 1px 3px;
    border: solid 1px #999;
    border-radius: 3px;
  }
  /* colors for highlight of text */
  
  .highlight-FFEB3B {
    background-color: #FFEB3B;
  }
  
  .highlight-80CBC4 {
    background-color: #80CBC4;
  }
  
  .highlight-B39DDB {
    background-color: #B39DDB;
  }
  
  .highlight-EF9A9A {
    background-color: #EF9A9A;
  }
  
  .highlight-F48FB1 {
    background-color: #F48FB1;
  }
  
  .highlight-81D4FA {
    background-color: #81D4FA;
  }
  
  .highlight-A5D6A7 {
    background-color: #A5D6A7;
  }
  
  .highlight-E6EE9C {
    background-color: #E6EE9C;
  }
  
  .highlight-FFF59D {
    background-color: #FFF59D;
  }
  
  .highlight-FFE082 {
    background-color: #FFE082;
  }
  
  .highlight-FFCC80 {
    background-color: #FFCC80;
  }
  
  .highlight-FFAB91 {
    background-color: #FFAB91;
  }
  
  .highlight-FF7043 {
    background-color: #FF7043;
  }
  
  .highlight-FFA726 {
    background-color: #FFA726;
  }
  
  .highlight-BCAAA4 {
    background-color: #BCAAA4;
  }
  
  .highlight-B0BEC5 {
    background-color: #B0BEC5;
  }
  
  /* alignment mismatch and positive */
  .mismatch {
    background-color: yellow;
  }
  
  .match {
    background-color: orange;
  }
  
  .lva-not-exact-match {
    text-transform: lowercase;
  }
  
  .lva-marked {
    background-color: #337ab7;
    color: white;
  }
  
  .lva-bar .tooltiptext {
    visibility: hidden;
    background-color: #777;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px 6px 6px;
    box-shadow: 0px 1px 7px #888;
  
    font-size: 7pt;
    font-family: arial;
  
    /* Position the tooltip */
    position: absolute;
    bottom: 10%;
    left: 100%;
    margin-left: 10px;
    z-index: 9999;
  
    overflow: visible;
  }
  
  .lva-bar .tooltiptext:before {
    content: '';
    position: absolute;
  
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #777;
    left: -6px;
  }
  
  .lva-bar:hover .tooltiptext {
    visibility: visible;
  }
  
  .lva-bar .singletooltiptext {
    visibility: hidden;
    background-color: #777;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px 6px 6px;
    box-shadow: 0px 1px 7px #888;
  
    font-size: 7pt;
    font-family: arial;
  
    /* Position the tooltip */
    position: absolute;
    bottom: 10%;
    margin-left: 2px;
    z-index: 10001;
  }
  
  .lva-bar .singletooltiptext:before {
    content: '';
    position: absolute;
  
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #777;
    left: -6px;
  }
  
  .lva-bar span:hover .singletooltiptext {
    visibility: visible;
  }
  
  .lva-bar span:hover ~ .tooltiptext {
    visibility: hidden;
  }
  .lvm-summary-header-row {
    width: 600px;
    text-align: center;
    margin-bottom: 10px;
  
    /*font-size: 9pt !important;*/
    font-weight: bold;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #777777;
  }
  
  .lvm-summary-header-label {
    width: 400px;
  }
  
  .lvm-summary-sub-header {
    float: left;
  
    /*font-size: 9pt !important;*/
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .lvm-summary-gqcol {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
    margin-left: 4px;
    display: inline-block;
    float: left;
    width: 104px;
  
    /*font-size: 9pt !important;*/
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .lvm-summary-gqcol:hover {
    overflow: visible;
    white-space: normal;
  }
  
  .lvm-summary-row {
    white-space: nowrap;
  }
  
  .lvm-summary-link {
    position: relative;
    margin-right: 4px;
    margin-left: 4px;
    left: 450px;
  }
  
  .lvm-summary-link-label {
    float: left;
    vertical-align: middle;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  /* used to highlight the word SEQ ID NO in the claim text */
  .seq-id-no {
    text-decoration: underline;
    font-weight: bold;
  }
  .alignment-chart {
    margin-top: 10px;
    margin-bottom: 10px;
    /* text-align: center; */
  }
  .hidden { display:none; }