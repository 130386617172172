:root {
    --backgroundColor: #a99a9a28 ; /* default light theme background color */
    --appTextColor: #777; /* default light theme text color */
}


/* Use variables throughout your CSS */
body {
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
} 

.buttonBar{
    padding: 10px 5px 5px 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: baseline;

}

.ontologyTable{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.OntologyBoxBorder{
    height: 400px;
    border: 1px solid #337AB7;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
    background-color: var(--backgroundColor);
}

.OntologyBoxBorderDark{
    height: 400px;
    border: 1px solid #337AB7;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
    background-color: #292929;
}

.ontologyItem{

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 2px;
    margin: 5px;
    width: fit-content;
    background-color: var(--backgroundColor);
}

.ontologyItemDark{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 2px;
    margin: 5px;
    width: fit-content;
    background-color: #292929;
}

.Ontologyformat{
    display: flex;
    flex-direction: column;
    padding-right: 5px;
}

.ftSearchButton{
    width: 64px;
    height: 36px;
    text-transform: none !important;
}


.linkPadding{
    padding: 5px;
}

.autoComplete {
    content: attr(placeholder);
    color: #a9a7a7;
    background-color: transparent;
    border: none;
}

.resInfoPopup{
    float: left;
    width: 620px;
    height: 350px;
    position: fixed;
    background-color: var(--backgroundColor);
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    top: 50%;
    left: 0;
    margin-top: -175px;
    margin-left: 310px;
    z-index: 400;
}

.resInfoPopupDark{
    float: left;
    width: 620px;
    height: 350px;
    position: fixed;
    background-color: #292929;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 0.3rem;
    outline: 0;
    top: 50%;
    left: 0;
    margin-top: -175px;
    margin-left: 310px;
    z-index: 400;
}

.margin15{
    margin: 15px;
} 

.resCounter{
    padding-top: 2px;
    margin-right: 83px;
    margin-bottom: -23px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    z-index: 13;
    flex-wrap: nowrap;
}

.resCounterNum{
    background-color: var(--backgroundColor);
    z-index: 14;
    border-radius: 5px;
    padding-right: 5px;
}

.resCounterNumDark{
    background-color: #292929;
    z-index: 14;
    border-radius: 5px;
    padding-right: 5px;
}

.paddingFix{
    padding: 6px 10px !important;
}

.historyPopup {
    float: left;
    width: 400px;
    height: 200px;
    position: fixed;
    background-color: var(--backgroundColor);
    border: 1px solid var(--appTextColor);
    border-radius: 0.3rem;
    outline: 0;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -200px;
}

.queryPopup {
    z-index: 1000;
    position: fixed;
    float: left;
    background-color: var(--backgroundColor);
    border: 1px solid var(--appTextColor);
    border-radius: 0.3rem;
}

.queryPopupQuery {
    vertical-align: top;
    text-align: left;
    width: 600px;
    padding: 10px;
    overflow-wrap: break-word;
    background-color: var(--backgroundColor);
}

.historyPopupHeader {
    color: rgb(119, 119, 119);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 0px;
    margin-left: 20px;
    text-align: left;
    text-size-adjust: 100%;
}

.historyPopupHeaderDark {
    color: white;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 0px;
    margin-left: 20px;
    text-align: left;
    text-size-adjust: 100%;
}

.ontologyHeader {
    color: rgb(119, 119, 119);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-size-adjust: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
}

.historyPopupHeaderHover {
    color: rgb(119, 119, 119);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 10px;
    text-align: left;
    text-size-adjust: 100%;
    background-color:  var(--backgroundColor);
}

.historyPopupBuffer {
    float: right;
    padding-top: 90px;
    padding-right: 20px;
}

.HistoryTable {
    width: 100%;

}

.HistoryItems {
    text-align: right;
    width: 100px;
    font-size: 13px;
    font-weight: 400;
    min-width: 80px;
    min-height: 55px;
}

.HistoryBorderRight {
    border-right-style: Solid;
    border-right-color: var(--background-color);
    border-right-width: .998px;
}

.HistoryBorderBottom {
    border-bottom-style: Solid;
    border-bottom-color: var(--appTextColor);
    border-bottom-width: .998px;
    padding-left: 10px;
    padding-right: 10px;
}

.HistoryHeaderFont {
    color: rgb(119, 119, 119);
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
}

.HistoryHeaderFontDark {
    color: white;
    font-size: 14px;
    font-weight: 700;
    box-sizing: border-box;
}

.HistoryHeaderRow {
    height: 55px;
}

.HistoryNoBorder {
    padding-left: 10px;
    padding-right: 10px;
}

.HistoryText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 13px;
    color: var(--appTextColor);
}

.HistoryTextDark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 13px;
    color: white
}
.HistoryDate {
    width: 180px;
}

.HistoryAlerts {
    font-weight: 400;
    font-size: 13px;
    width: 80px;
    color: #777777;
}

.HistoryAlertsSpacer {
    font-weight: 400;
    font-size: 13px;
    width: 150px;
    color: #337AB7;
}

.HistoryAlertsSpacer:hover {
    text-decoration: underline;
    cursor: pointer;
}

.HistoryLabel {
    color: #337AB7;
    font-weight: 400;
    font-size: 13px;
}

.HistoryLabel:hover {
    text-decoration: underline;
    cursor: pointer;
}

.HistoryTableRows td {
    padding-bottom: 8px;
    height: 50px;
    color: var(--appTextColor);
}

.HistoryPad15 {
    padding-right: 15px;
}

.HistoryTopper {
    Padding: 15px;
    padding-top: 20px !important;
    width: min-content;
    font-size: 14px;
    margin: auto;
    text-align: left;
    color: rgb(51, 122, 183);
    cursor: pointer;
    font-weight: 400;
    text-wrap: nowrap;
}

.HistoryHeader {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.HistoryWidth {
    width: 970px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.HistoryFix {
    height: 100%;
    margin-top: 3px;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 0px;
}

.ontologyScrollFix {
    max-height: 500px;
    overflow: scroll;
}

.fixItemWidth {
    padding-left: 35px;
}

.disabledHistory {
    pointer-events: none;
    color: #ccc;
    cursor: not-allowed;
    Padding: 15px;
    padding-top: 20px !important;
    width: min-content;
    margin: auto;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

.CreateOntology{

    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
}

.activeOntology{

    background-color: #0182c5;
    color: white !important;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    text-wrap: nowrap;

}


.activeOntoItem{

    background-color: #0182c5;
    color: white !important;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
}

.notActiveOntology{

    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    text-wrap: nowrap;

}

.notActiveOntoItem{

    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;

}


.notActiveOntology{

    padding-left: 5px;
    padding-right: 5px;
    border-radius: 6px;
    text-wrap: nowrap;

}

.synonymEditorTerm{
    width: fit-content;
    padding: 3px;
}

.fullWidthCenterAlign{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
}

.CenterOntologies{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1px;
    margin-bottom: 3px;
}

.ontologyBar{
    padding: 15px;
    display: flex;
    justify-content: center;
}

.dragBox{
    cursor: ew-resize;
    margin-left: -5px;
    margin-right: -5px;
    width: 10px;
    /* border: 2px solid white; */
}

.dragBox:hover{
    margin-left: -5px;
    margin-right: -5px;
    cursor: ew-resize;
    width: 10px;
    background-color:  rgb(136,136,136);
    border-radius: 3px;
    border: 2px solid white;
}

.dragBoxDragging{
    margin-left: -5px;
    margin-right: -5px;
    cursor: ew-resize;
    width: 10px;
    background-color:  rgb(136,136,136);
    border-radius: 3px;
    border: 2px solid white;
}

.dragPadding{
    padding: 2px;
    background-color: #292929;
}

.createOntology{
    display: flex;
    border-radius: 5px;
    padding: 2px 5px;
    height: 25px;
    background-color: #0182c5;
    border: 1px solid #1f4e79;
    color: var(--appTextColor);
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.createOntologyUST{
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;
    margin-bottom: 7px;
    height: 30px;
    background-color: #0182c5;
    border: 1px solid #1f4e79;
    color: var(--appTextColor);
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 110px;
}

.cancelOntologyUST{
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;
    margin-bottom: 7px;
    height: 30px;
    background-color: #db862c !important;
    border: 1px solid #ca751b !important;
    color: var(--appTextColor);
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 110px;
}

.ontologyLeftFormat{
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createButtonDiv{
    width: 100%;
    /* align-content: center; */
    display: flex;
    padding-left: 1%;
    justify-content: left;
    align-items: center;
}

.borderBlue{
    border: 1px solid #337AB7;
    border-radius: 8px;
}

.borderGrey{
    border: 1px solid #e2e2e2d6;
    border-radius: 8px;
}

.leftTableInnerContainer{
    width: 99%;
    height: 99%;
    overflow-x: clip;
    background-color: white;
    overflow-y: scroll;
}

.leftTableInnerContainerDark{
    width: 99%;
    height: 99%;
    overflow-x: clip;
    background-color: #292929;
    overflow-y: scroll;
}


.ontologyTextArea{
    width: 99%;
    margin-left: auto;
    margin-right: auto;
    height: 350px;
    background-color: white;
    padding: 5px;
    overflow-y: scroll;
}

.ontologyTextAreaDark{
    width: 99%;
    margin-left: auto;
    margin-right: auto;
    height: 350px;
    background-color: #292929;
    padding: 5px;
    overflow-y: scroll;
}

.centerEditortext{
    width: 100%;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-Index: "150"
}

.font18pt{
    font-size: 18pt;
}

.OntologyPopup {
    width: 400px;
    height: 220px;
    position: fixed;
    background-color: var(--backgroundColor);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    outline: 0;
}

.fixedFullScreen{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputBorder{
    border: none;
    border-bottom: 1px solid;
    outline: none;
    font-weight:bold;
}

.inputBorderError{
    border: none;
    border-bottom: 1px solid red;
    outline: none;
    font-weight:bold;
}

.newEditButton{
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1px 3px;
    margin: auto 2px;
    border-radius: 4px;
}

.ontoCompleteContainer{
    display: flex;
    position: absolute;
    width: 300px;
    place-content: center flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.tinyBlueLeftArrow{
    position: relative;
    z-index: 320;
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-right: 5px solid #337AB7; 
    border-bottom: 5px solid transparent;
      
}

.ontoPopupBackground{
    position: relative;
    z-index: 310;
    margin-top: -45px; 
     margin-left: 10px;
    padding: 5px;
    border: 1px solid darkgray;
    border-radius: 8px;
    background-color: var(--backgroundColor);
    color: var(--appTextColor);
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    width: 300px;
    height: 320px;
}

.ontoCompleteHeadDisaplay{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #000000;
}

.ontoClose{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5px;
}

.ontoSuggestions{
    width: 100%;
    height: 280px;
    margin-top: 2px;
    border-radius: 8px;
    border: 1px solid rgba(169, 169, 169, 0.515);
}

.ftdocsidebar{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    height: 100%;
}

.ftDocFixedSide{
    height: 100%;
    position: fixed;
    right: 0;
    z-index: 999;
}

.scrollable-content {
    overflow-y: scroll;
    overflow-x: visible;
  }

.resultsNewTabLayout{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.resultsTabIcon{
    width: 10%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.tabIconWH{
    width: 50px !important;
    height: 50px !important;
}

.docContainer{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: nowrap;
    justify-content: left;
    width: 100%;
}

.fthighlightDiv{
    height: 30px;
    display: flex;
    flex-direction: row-reverse;
    margin-right: -50px;
}

.seqidSelector{
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    height: 30px;
}

.keywordHighlight{
    color: var(--appTextColor);
    padding: 1px 2px;
    border-radius: 4px;
    text-wrap: nowrap;
}

.highlight-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example shadow */
    transition: box-shadow 0.3s ease-in-out;
}

.seqidHighlight{
    color: var(--appTextColor);
    background-color: #ffb362;
    padding: 1px 2px;
    border-radius: 4px;
}


.floatingKwicContainer{
    display: flex;
    position: fixed;
    top: -5px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.floatingkwic{
    width: 352px;
    border:  2px solid grey;
    border-radius: 4px;
    background-color: var(--backgroundColor);
    padding: 2px 5px;
    z-index: 300;
}

.openKwicButton{
    margin-right: -5px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    border: 1px solid grey;
    border-radius: 4px;
    height: 50px;
    padding-left: 10px;
    padding-right: 20px;
    color: #0182c5;
    flex-direction: row;
    align-items: center;
    background-color: var(--backgroundColor);
}

.docHeaderPadding{
   padding: 20px 10px;
}

.classTableLink{
    max-width: 600px;
    height: auto;
    border-bottom: 0px;
    overflow-wrap: break-word;
    white-space: break-spaces !important;
}

.tableDiv{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.CreateOntologyLabelBox{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.disableFixer{
    display: flex;
    width: fit-content;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.loadingContainer{
    width: 100%;
    height: 100%;
    display: flex;
}

.ontoTooltip{
    width: auto;
    font-size: 12px;
    text-align: justify;
    padding: 8px 14px 8px 14px;
    box-Shadow: 0px 1px 7px #888;
    background-Color: #777;
    color:#FFF;
}

.buttonBuffer {
    width: 100%;
    padding-top: 70px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
}

.importdiv{
    width: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
}

.importError{
    color: red;
    margin-bottom: -21px;
}

.exportDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
