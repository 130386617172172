:root {
    --backgroundColor: #000000 ; /* default light theme background color */
    --appTextColor: #777; /* default light theme text color */
}


/* Use variables throughout your CSS */
body {
    background-color: var(--backgroundColor);
    color: var(--appTextColor);

} 

.ftEnhancement{
    border: 1px solid darkgray;
    border-radius: 3px;
    margin-top: 5px;
    padding: 2px 3px;
    font-size: 14px;
    color: #616161;
    font-weight: 400;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    white-space: -moz-pre-space;
    white-space: break-spaces;
    width: 100%;
    min-width: 400px;
    min-height: 100px;
    z-index: 300;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    outline: 0px solid #337AB7;
}

.ftEnhancementDark{
    border: 1px solid darkgray;
    border-radius: 3px;
    margin-top: 5px;
    padding: 2px 3px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    white-space: -moz-pre-space;
    white-space: break-spaces;
    width: 100%;
    min-width: 400px;
    min-height: 100px;
    z-index: 300;
    background-color: #292929;
    margin-left: auto;
    margin-right: auto;
    outline: 0px solid #337AB7;
}
.ftOptionsButtons{
    margin: 5px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
}

.ftCustomOntologyList {
    width: 100%;
    height: 270px;
    border-radius: 8px;
    border: 1px solid rgba(169, 169, 169, 0.515);
    margin: 5px 0 5px;

    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    background-color: var(--backgroundColor);
    background-color: -moz-field;

    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--appTextColor);
    font-weight: 400;
    white-space: -moz-pre-space;
    white-space: break-spaces;
}

.ftOntologyItem{
    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    padding: 2px;
    /* margin: 5px; */
    width: fit-content;
    background-color: var(--backgroundColor);
}

.ftPaddingLeft5{
    padding-left: 5px;
}

.ftCustomHeader{
    color: var(--appTextColor);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-size-adjust: 100%;
}

.ftContainer{
    justify-content: center;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
}

.ftPositionRelative{
    position: relative;
}

.ftPopupBackground{
    position: absolute;
    z-index: 310;
    margin-top: 10px;
    margin-left: -150px;
    padding: 5px;
    border: 1px solid darkgray;
    border-radius: 8px;
    color: var(--appTextColor);
    background-color: var(--backgroundColor);
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    width: 300px;
    height: 350px;
}

.ftOntologySuggestions{
    width: 100%;
    height: 310px;
    margin-top: 2px;
    border-radius: 8px;
    border: 1px solid rgba(169, 169, 169, 0.515);
}

.ftAutoBox{
    width: 100%;
    height: 300px;
    margin-top: 2px;
    border-radius: 8px;
    border: 1px solid rgba(169, 169, 169, 0.515);
}

.ftAutoXIcon{
    width: 12px !important;
    height: 12px !important;
    padding: auto;
}
.ftAutoXIcon:hover{
    width: 12px !important;
    background-color: darkgrey;
    border-radius: 3px;
    cursor: pointer;
}

.ftScrolly{
    overflow-y: scroll;
}

.ftScrollx{
    overflow-x: scroll;
}

.ftDisplayNone{
    display: none;
    position: relative;
}
.ftDisplay{
    display: contents;
}

.ftNoLineBreak{
    flex-wrap: nowrap;
    break-inside:avoid;
}

.ftPopup{
    position: absolute;
    float: left;
}

.tinyBlueUpArrow{
    position: absolute;
    z-index: 320;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #337AB7;
      
}

.ftmarginFix{
    margin-left: -125px;
    margin-top: 10px;
}

.ftHeader{
    color: var(--appTextColor);
    font-size: 14px;
}

.ftAcKeyGroup{
    padding-left: 0;
    padding-top: 10px;
}

.ftTabsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
}

.ftontologySpacer{
    height: 30px;
    margin-bottom: -10px;
    padding-right: 5px;
}
.ftLabel{
    font-size: 14px;
    padding: 3px;
    z-index: 13;
    position: relative;
}

.ftLabel:hover {
    color: #3c763d;
    background-color: var(--backgroundColor);
    border: 1px solid #d6e9c6;
    border-radius: 4px;
    cursor: pointer;
}

.ftAlwaysGreenLabel {
    display: flex;
    color: #3c763d;
    background-color: var(--backgroundColor);
    border: 1px solid #d6e9c6;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 25px;
    margin-right: 5px;

}

.ftInsertButtonActive{
    border-radius: 5px;
    padding: 2px 5px;
    height: 25px;
    position: absolute;
    background-color: #0182c5;
    border: 1px solid #1f4e79;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.createOntologyButton{
    border-radius: 5px;
    padding: 2px 5px;
    height: 25px;
    position: absolute;
    background-color: #0182c5;
    border: 1px solid #1f4e79;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.ftInsertButtonNot{
    border-radius: 5px;
    padding: 2px 5px;
    height: 25px;
    position: absolute;
    background-color: grey;
    border: 1px solid #1f4e79;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    top: 10px;
    right: 10px;
    cursor: default;
}

.errorListItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
}

.ftErrorTinyWhiteBox{
    width: 10px;
    height: 10px;
    background-color: white;
    z-index: 20;
    position: absolute;
    right: -9px;
}

.ftOutterErrorBevel{
    display: inline-flex;
    border: 1px solid darkgray;
    border-radius: 10px 0 0 0;
    width: 10px;
    height: 11.2px;
    border-bottom: none;
    border-right: none;
    position: relative;
    top: -7px;
    right: -1px;

}

.ftTinyWhiteBox{
    width: 10px;
    height: 10px;
    background-color: white;
    z-index: 11;
    position: absolute;
    margin: 16px 0 0 -9px;
}

.ftOutterBevel{
    display: inline-flex;
    border: 1px solid darkgray;
    border-radius: 0 0 8px 0;
    width: 10px;
    height: 10px;
    margin-bottom: -9px;
    margin-right: -1px;
    border-left: none;
    border-top: none;
    position: absolute;
    z-index: 12;
}

.ftZFix{
    position: relative;
    z-index: 12;
    padding-right: 5px;
}

.ftOntologies {
    background-color: var(--backgroundColor);
    border:  1px solid darkgray;
    border-radius:  8px 8px 0 0;
    padding: 3px 3px 10px 3px;
    overflow: hidden;
}

.ftBrackets{
    border: 1px solid #bbbbbb;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 1px;
}

.blueText{
    color: #31708f;
}

.ftBoldItem{
    font-size: 16px;
    font-weight: bold;
}

.ftNumberLetter{
    color: #777777;
    font-weight: bold;
    text-transform: uppercase;
}

.ftFieldStackBox{
    display: flex;
    width: 100%;
    height: 35px;
    background: var(--backgroundColor);
    color: var(--appTextColor);
    border: 1px solid lightgray;
    border-radius: 8px;
    flex-direction: row;
    flex-wrap: nowrap;
    /* align-content: center; */
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
}

.ftSFText{
    font-weight: 200;
    color: darkgray;
    padding: 3px;
}

.ftEnhancementOptions{
    justify-content: right;
    display: flex;
    margin-right: 5%;
    margin-left: auto;
}

.ftJumboHeight{
    height: 210px;
}

.ftEnhancePopupHeight{
    height: 270px !important
}

.ftEnhanceTitle{
    padding: 0;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 0;
}

.font14{
    font-size: 14px;
    color: #777777;
}


.ftErrorPopup{
    position: absolute;
    margin-left: 5px;
    border-left: 1px solid darkgray;
    border-right: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    border-radius: 0px 0px 8px 8px;
    background-color: white;
    width: auto;
    min-width: 175px;
    max-height: 200px;
    max-height: 50%;
    z-index: 320;
}

.ftErrorListContainer{
    margin: 3px;
    position: relative;
    min-width: 175px;
    width: auto;
    max-height: 200px;
    margin-top: 2px;
    border-radius: 8px;
    border: 1px solid rgba(169, 169, 169, 0.515);
    overflow-y: scroll;
}

.ftErrorText {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-style: italic;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.71);
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.ftErrorText:hover {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-style: italic;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.71);
    border-radius: 5px;
    background: rgba(128, 128, 128, 0.239);
    padding-left: 4px;
    padding-right: 4px;
}

.ftSynonymPopup {
    z-index: 1000;
    max-height: 50%;
    position: fixed;
    width: auto;
    background-color: var(--backgroundColor);
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
}

.ftSynonymPopup.arrow-left:after {
    content: " ";
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid black;
    border-left: none;
    border-bottom: 15px solid transparent;
}

.ftAdd{
    display: flex;
    margin-left: 5px;
    font-size: small;
    color: white;
    background-color: #31708f;
    border: 1px solid grey;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    justify-content: center;
    align-items: center;

}

.ftRemove{
    margin-left: 5px;
    font-size: small;
    color: white;
    background-color: rgb(255, 106, 106);
    border: 1px solid grey;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.documentHighlightsContainer{
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(169, 169, 169, 0.515);
}